import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Container from '../components/Container';
import ComponentWrapper from '../components/ComponentWrapper';
import Layout from '../components/Layout';
import Cta from '../components/Cta';
import Heading from '../components/typography/Heading';
import SEO from '../components/Seo';

const ContentWrapper = styled.article`
  max-width: 75rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 10rem auto 0 0;
  }
`;

const dienst = ({ data }) => {
  const { titel, tekst } = data.prismicDiensten.data;
  return (
    <Layout>
      <SEO title={titel.text} />
      <Container>
        <ComponentWrapper>
          <ContentWrapper>
            <Heading>{titel.text}</Heading>
            <div dangerouslySetInnerHTML={{ __html: tekst.html }} />
          </ContentWrapper>
        </ComponentWrapper>
      </Container>
      <Cta />
    </Layout>
  );
};

export default dienst;

export const pageQuery = graphql`
  query dienst($id: String!) {
    prismicDiensten(id: { eq: $id }) {
      data {
        titel {
          text
        }
        tekst {
          html
        }
        icon {
          url
        }
      }
    }
  }
`;
